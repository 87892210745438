@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.wrap {
    background: green;
    width: 500px;
    position: relative;
    height: 50px;
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 50px;
  }

  .inner {
    position: relative;
    height:50px;
    /*width will depend on the total with of the sectioin blocks to contain*/
    width: 1020px;
    max-height: 50px;
    background: pink;
    
  }
  
  section {
    position: relative;
    font-size: 12px;
    color: black;
    background: yellow;
    width: 170px;
    height: 50px;
    float: left;
    opacity: .9;
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: pre-wrap !important;
 }

 .ag-theme-alpine {
  width: inherit;
  --ag-header-background-color: rgba(176, 204, 250, 0.15);
  max-width: 100%;
  
 }

 .ag-body-horizontal-scroll{
  min-height: 4px !important;
  height: 4px !important;
  cursor: pointer;
 }


 .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
	border-radius: 2px;
	background-color: #f5f5f562;
 }

 .ag-body-horizontal-scroll-viewport::-webkit-scrollbar{
  min-width: 5px !important;
	background-color: #F5F5F5;
 }

 .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #5b5f6141;
}


 .ag-body-vertical-scroll{
  min-width: 4px !important;
  width: 4px !important;
  cursor: pointer;
 }

 .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
	border-radius: 2px;
	background-color: #f5f5f562;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar
{
	min-width: 5px !important;
	background-color: #F5F5F5;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #5b5f6141;
}


.ag-theme-alpine .ag-header-row {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  
  background-color: rgba(172, 178, 189, 0.192);
}

.ag-theme-alpine .ag-cell {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400 !important
}

.ag-root-wrapper {
  border: 1;
}

.ag-header {
  border-bottom: 1px solid #e0e8f5;
}

.ag-ltr .ag-cell {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: start;
}

.ag-row:hover {
    input:read-only {
    color: #777777 !important;
    color: red !important;
  }
}

.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: rgba(39, 122, 255, 0.144);
}

.filter-style {
    width: 100%;
    height: 80px;
    border: 1px solid gray;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
}

.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}

.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}

.w3-border{border:1px solid #ccc!important}

