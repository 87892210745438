@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.ag-header-cell-label {
    @apply font-semibold text-sm text-black/70
}

.ag-cell {
    @apply text-[12px] font-light subpixel-antialiased
}

