.home-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color: rgb(26, 25, 25);
    height: 100vh; /* Full viewport height */
  }
  
  .logo-container img {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
  }
  
  .start-button-container {
    z-index: 1;
    display: block;
    /* background-color: white; */
  }
  .custom-button {
    font-size: 1.5rem; /* Adjust the font size */
    padding: 0.75rem 1.5rem; /* Adjust the padding */
}

  