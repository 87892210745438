* {
    box-sizing: border-box;
  }
  
 
  
  button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
  }
  
  .sidebar-new {
    position: fixed;
    top: 12px;
    left: 6px;
    bottom: 24px;
    width: 50px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 250px;
    border-radius: 16px;
    padding: 0 16px;
    background-image: url("bg.jpg");
    backdrop-filter: blur(5px);
    transition: width 0.4s;
  }
  
  .sidebar-new header {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 1.25rem 0 0;
    border-bottom: 1px solid rgb(255 255 255 / 8%);
  }
  
  .sidebar-new header button {
    width: 52px;
  }
  
  .sidebar-new header img {
    height: 24px;
  }
  
  .sidebar-new button {
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    height: 50px;
    width: 100%;
    border-radius: 6px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 0 16px;
    color: rgb(255 255 255 / 95%);
  }
  
  .sidebar-new button p:nth-child(2) {
    flex: 1 1 auto;
  }
  
  .sidebar-new button:is(.active, :hover) {
    background: rgb(0 0 0 / 40%);
  }
  
  
  .sidebar-new button.active > i:nth-child(3) {
    rotate: -180deg;
  }
  
  .sidebar-new button:not(.active):hover {
    background: rgb(0 0 0 / 10%);
    text-decoration: none;
  }
  
  .sidebar-new ul {
    display: grid;
    list-style: none;
    padding: 0;
    margin: 0px;
    width: 100%;
  }
  
  .sub-menu {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: 0.5s;
  }
  
  .sub-menu ul {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
  }
  
  .sub-menu button {
    padding-left: 52px;
  }
  
  .sub-menu button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 24px;
    translate: 0 -50%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 35%);
  }
  
  
  .sidebar-new a {
    text-decoration: none; 
  }

