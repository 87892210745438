body {
    color:black
  }

  
  .header {
    padding: 10px;
    color: white;
    display: inline-block; 
    width: 450px; 
   
}

.red{
  background-color: red;
}
  .blue {
    background-color: #1998ff;
  }
  .black {
    background-color: rgb(18, 17, 17);
  }
  .order-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 520px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(235, 232, 232); 
    z-index: 99999999999;
  }

  .form div {
    margin-bottom: 2px;
  }
  .form-div {
    padding:0 18px;
  }
  select,
  option {
    cursor: pointer;
  }
  .close-btn {
    position: relative;
    font-size: 1.3rem;
    margin-left: 10px;
    border: none;
    cursor: pointer;
    color: black;
  }
  .close-btn:hover {
    transform: scale(1.4);
  }
 
  .formContainer{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 80vw;
    height: 70vh;
    padding: 20px; 
    border-radius: 10px;
  }
  .form-label{
    font-size: .9rem;
    color: black;
  }
  .open-btn {
    padding: 5px 20px;
    margin-right: 10px;
    font-size: larger;
    border-radius: 2px;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .open-btn:hover {
    transform: scale(0.9);
  }
  
  .submit-btn:hover {
    transform: scale(0.9);
  }
  
  .input-size,
  .transaction-size {
    width: 150px;
    padding-left: 5px;
    color: rgb(7, 7, 7);
    border-color: rgb(188, 187, 187) ;
    border-radius: 5px;
    background-color: white;
    height: 40px;
    font-size: 0.9rem;
  }

  .tab {
    display: flex;
    gap: 10px;
  }
  .exchange{
    display: flex;
    flex-direction: column;
  }
 
  
  .form-error{
    font-size: smaller;
    color: rgb(245, 51, 51);
  }
  
  .line-style {
    border-top: 1px solid rgb(224, 11, 11);
    width: 100%;
    margin: 20px 0;
    display: inline;
  }
 
  .submit-btn {
    margin-bottom: 20px;
    position: relative;
    left: 310px;
    font-size: 0.9rem;
    padding: 7px 25px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .cancel-btn {
    margin-bottom: 20px;
    position: relative;
    left: 300px;
    font-size: 0.9rem;
    padding: 7px 25px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    color: white;
  }
  
  .btn-container{
    padding-bottom: 20px;
    padding-left: 20px;
    width: 150px;
    font-size: 0.9rem;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    display: flex;
    gap:10px
  }
  label {
    display: inline;
  }
  /* .quantity-flex {
    padding: 0 20px;
  }
   */