#graphTemplate{
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#securityGraph{
  position: absolute; /* Position the Highcharts container absolutely */
  top: 7%;
  left: 0;
  z-index: 0;
}

#comparisionChart{
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 15%;
  padding-left: 20%;
  padding-right: 20%;
  margin-top: -7.5%;
  /* transform: translate(50%, -50%); */
  text-align: center;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

#form{
  /* background-color: 'rgba(0,0,0,0.5)';  */
   /* backdrop-filter: blur(5px); */
  height: 100%;
  width: 100%;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: 1
}