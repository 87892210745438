#externalTradeFormContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;  
}

#externalTradeForm{
   /* background-color: blueviolet; */
    width: 50vh;
    height: 80%;
    border: 1px solid white;
    border-radius: 1%;
    padding: 2%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.file-image{
    /* background-color: aquamarine; */
    position: relative;
    left: 45%;
}
.file-upload-box{
    height: 9rem;
    border: 2px solid rgb(176, 173, 173);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1%;
    border-style: dashed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}
small{
    font-size: small;
    color: rgb(179, 176, 176);
}
.file-input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
  }

.fileName{
    display: flex;
}

