#derivativeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
#derivativeGraph {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; 
  }
  
  #derivativePopover{
    z-index: 2;
  }

  #derivativeChart {
    position: absolute;
    top : 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 30%;
    padding-top: 20%;
    padding-right: 30%;
    text-align: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }
  