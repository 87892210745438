.show-option {
  text-decoration: none;
  color: black;
  font-size: 0.8rem;
  margin-left: 10px; 
}
.container {
  padding: 0 20px;
}
.validity-show {
  width: 20px;
  display: flex;
  gap: 30px;
  margin : 10px 0 0 10px;
}
.radio-btn {
  cursor: pointer;
}
