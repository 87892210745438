.option-container {
    width: 100%;
    height: 85vh;
    display: flex; 
    /*align-items: center;
    justify-content: center; */
    /* background-color: blue; */
}

.option-table {
    position: absolute; /* Ensure this is positioned relative to the container */
    z-index: 0;
    width: 100%;
    height: 100%;
    top:20%;
}

.order-form {
    position: absolute; 
    z-index: 1;
    top:20%;
    left: 25%;    
}
